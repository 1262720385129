import _ from "lodash";

export function displaySpaceAwardedDropAction(availability, spaceUsageBuilder) {
  return (
    availabilityId(availability, spaceUsageBuilder) &&
    _.get(availability, "state") === "under_contract"
  );
}
export function spaceField(availability) {
  return availability?.space;
}
export function topLevelSpaceDataField(spaceObject, availability) {
  return spaceObject?.dataField || availability?.topLevelSpace;
}
export function matchingAvailabilityGroup(availability, spaceUsageBuilder) {
  return _.find(
    _.get(spaceUsageBuilder, "availabilityGroups", []),
    function (group) {
      return _.find(group.availabilities, function (inv) {
        const localAvailabilityId =
          _.get(availability, "existingAvailability.id") ||
          _.get(availability, "id") ||
          _.get(availability, "temporaryId");

        return _.get(inv, "existingAvailability.id") === localAvailabilityId;
      });
    },
  );
}
export function focalAvailability(availability, spaceUsageBuilder) {
  if (matchingAvailabilityGroup(availability, spaceUsageBuilder)) {
    return _.find(
      matchingAvailabilityGroup(availability, spaceUsageBuilder).availabilities,
      function (av) {
        const localAvailabilityId =
          _.get(availability, "existingAvailability.id") ||
          _.get(availability, "id") ||
          _.get(availability, "temporaryId");

        return _.get(av, "existingAvailability.id") === localAvailabilityId;
      },
    );
  } else {
    return availability;
  }
}
export function availabilityId(availability, spaceUsageBuilder) {
  return (
    _.get(
      focalAvailability(availability, spaceUsageBuilder),
      "existingAvailability.id",
    ) ||
    _.get(focalAvailability(availability, spaceUsageBuilder), "id") ||
    _.get(focalAvailability(availability, spaceUsageBuilder), "temporaryId")
  );
}
export function localSpaceUsers(availability, spaceUsageBuilder) {
  return _.get(
    focalAvailability(availability, spaceUsageBuilder),
    "existingAvailability.spaceUsers",
    _.get(focalAvailability(availability, spaceUsageBuilder), "spaceUsers", []),
  );
}
export function usersFetchRequestKey(availability) {
  return `availability_space_users_${availability?.id}`;
}
export function awardedUsersFetchRequestKey(availability) {
  return `availability_space_users_${availability?.id}_awarded`;
}
